import { combineReducers } from 'redux';
import { userAuthSlice } from './userAuthSlice';
import { examSlice } from './examSlice';
import { notification } from './notification';
export const rootReducer = combineReducers({
  adminData: userAuthSlice.reducer,
  examData: examSlice.reducer,
  notification: notification.reducer
});


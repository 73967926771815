import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationCount: null,
};

export const notification = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {

    setNotificationCount(state, action) {
      state.notificationCount = action.payload;
    },
  },
});

export const { setNotificationCount } = notification.actions;
export default notification.reducer;